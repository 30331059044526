@use "sass:math";
.course-thumbnail {
  background-color: rgba(0, 0, 0, 0.07);
  min-height: 5em;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: math.percentage(math.div(2, 3));
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
