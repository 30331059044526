.radio-buttons {
    margin: 0 0 1em;
    .radio-button {
        &:first-child { 
            margin-inline-start: 0;
        }
        color: inherit;
        text-decoration: none;
        font-size: 1.2em;
        margin: 0 0.7em;
        cursor: pointer;
        font-weight: 400;
        background: none;
        border: 0;
        padding: 0;
        outline: 0;
        i {
            margin-inline-end: 0.3em;
        }
        &:hover {
            color: var(--main-color);
        }
        &.is-selected {
            > span {
                font-weight: 700;
                border-bottom: 0.15em solid;
            }
            color: var(--main-color);
        }
    }
}
