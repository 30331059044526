$text: #0f1e3a;
$blue: #55d0cc;

// ---------

:root {
    --main-color: #55d0cc;
    --text-color: #0f1e3a;
    --offset-text-color: #fff;
    --light-hover-color: #e1f4f2;
    --error-color: #f85863;
    --error-color-hover: #ffe0e2;
}

.error-color {
    color: var(--error-color);
}

.svg-fill-with-text-color {
    fill: var(--text-color);
}

$border: #d4d5d8;

$main: $blue;
$yellow: #febf6d;
$orange: #fc9c59;
$red: #ff5f6b;

$white: #fff;
$darkblue: #252a41;
$verydarkhr: rgba(78, 85, 116, 0.3);
$darkhr: #4d5473;
$verydarktext: #4d5473;
$icontext: #777b91;
$darktext: #888da8;
$darktexthover: #9ca1bb;
