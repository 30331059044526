.dropdown-content {
    color: var(--text-color);
    display: none;
    position: absolute;
    background-color: var(--offset-text-color);
    min-width: 10em;
    box-shadow: 0 0.3em 1em 0 rgba(0, 0, 0, 0.15);
    left: 0em;
    top: 100%;
    z-index: 1;
    transform-origin: top left;
    @keyframes visibleFromTop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            transform: translateY(0em);
        }
    }
    animation: visibleFromTop 0.15s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
    a {
        color: var(--text-color);
        padding: 0.75em 1em;
        text-decoration: none;
        display: block;
        font-size: 0.85em;
    }
    &:hover,
                        &:focus {
                            a:hover {
                                background-color: var(--offset-text-color);
                                color: var(--text-color);
                            }
                        }
}