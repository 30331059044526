.clean {
  border: none;
  background: none;
  display: inline-block;
  font-size: 1em;
  padding: 0;
  text-decoration: none;
  color: inherit;
}
button {
  &.transparent {
    background: none;
    padding: 0.7em 0.4em;
    border: 0;
    outline: none;
    font-size: 1em;
    color: inherit;
    &:hover {
      color: var(--main-color);
      cursor: pointer;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.19);
      pointer-events: none;
    }
  }
}
button,
.button {
  i {
    margin-inline-end: 0.4em;
  }
  &.fullwidth {
    width: 100%;
  }
}
.button {
  border: none;
  padding: 0.9em 1.2em;
  color: white;
  box-shadow: 0 0.225em 0.375em 0 rgba(0, 0, 0, 0.1);
  background-color: var(--main-color);
  border-radius: 0.4em;
  cursor: pointer;
  font-size: 1em;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  position: relative;
  transition: 0.15s all;

  &.rounded {
    border-radius: 99em;
    &:before {
      border-radius: 99em;
    }
  }

  &:before {
    transition: 0.15s all;
    content: "";
    position: absolute;
    left: 0.1em;
    right: 0.1em;
    top: 0.1em;
    bottom: 0.1em;
    background: linear-gradient(-185deg, white, transparent, transparent);
    border-radius: 0.25em;
    box-sizing: border-box;
    pointer-events: none;
    filter: opacity(0.5);
    opacity: 0.3;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    &:before {
      display: none;
    }
  }

  &.small {
    padding: 0.6em 1.1em;
    line-height: 1.2;
  }

  &:hover {
    transform: translateY(-0.1em);
    box-shadow: 0 0.13em 0.4em 0 rgba(0, 0, 0, 0.1);
    &:before {
      opacity: 0.7;
    }
  }

  &:active {
    transition: 0.05s all;
    transform: translateY(-0em);
    box-shadow: 0 0.225em 0.375em 0 rgba(0, 0, 0, 0.1);
    &:before {
      box-shadow: 0 0.225em 0.375em 0 rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
  }

  &:focus {
    outline: var(--offset-text-color);
  }

  &.hollow {
    color: var(--course-theme-light-main);
    border: 2px var(--course-theme-light-main) solid;
    background: transparent !important;
    font-weight: 700;
    box-shadow: none;
    &:before {
      display: none;
    }
    &:hover,
    &:active {
      transform: initial;
    }
    &.inset {
      border: 0px var(--course-theme-light-main) solid;
      box-shadow:  var(--course-theme-light-main) 0px 0px 0 1.5px inset !important;
    }
  }
  // &.main {
  // }
}
