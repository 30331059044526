// @use 'sass:math';


@import "vendor/include-media";
@import "vendor/reset";

@import "base/grid";
@import "base/colors";
@import "base/variables";
@import "base/typography";
@import "base/headings";
@import "base/base";
@import "base/buttons";
@import "base/general";
@import "base/input";
@import "base/responsive";
@import "base/helpers";
@import "base/header-navigation";

@import "components/NiceSwitch";
@import "components/progressbar";
@import "components/notifications";
@import "components/development-notice";
@import "components/dashed-wrap";
@import "components/dropdown";
@import "components/radio-options";
@import "components/course-thumbnail";
// @import "components/link-copied";
// @import "components/bouncing-letters";
// @import "components/social-icons";


@import "pages/course-and-lesson.scss";


@import "./vendor/font-awesome-5/css/fontawesome-all.css";
// @import "./vendor/fontawesome-free-6.2.0-web/css/all.css";
// @import "./vendor/fontawesome-free-6.2.0-web/scss/fontawesome.scss";
// @import "./vendor/fontawesome-free-6.2.0-web/scss/brands.scss";
// @import "./vendor/fontawesome-free-6.2.0-web/scss/regular.scss";
// @import "./vendor/fontawesome-free-6.2.0-web/scss/solid.scss";

body > iframe {
    display: none;
}