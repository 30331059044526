@use 'sass:math';

.nice-switch {
    display: inline-flex;
    cursor: pointer;
    justify-content: space-between;
    vertical-align: middle;
    .toggle-label {
        margin-inline-start: 0.8em;
        user-select: none;
        line-height: 1.5;
        font-weight: bold;
    }

    .toggle-bar {
        display: inline-block;
        // flex-grow: 1;
        border-radius: 2em;
        // border: 0.1em solid var(--main-color);
        // padding: 0.15em;
        // margin: -0.45em 0.5em;
        width: 2.7em;
        $circle_size: 1.8em;
        height: $circle_size * 0.75;
        box-sizing: content-box;
        opacity: 0.35;
        transition: all 150ms ease-out;

        position: relative;
        &:before {
            border-radius: 2em;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background: var(--text-color);
            opacity: 0.35;
        }

        & > .inner {
            position: relative;
        }

        .toggle-circle {
            position: absolute;
            right: 0;
            transition: all 150ms ease-out;
            margin: math.div(-$circle-size * 0.25, 2) 0;
            width: $circle_size;
            height: $circle_size;
            border-radius: 100%;
            background: var(--text-color);
            transform: translate(10%);
        }
    }
    &.light {
        .toggle-bar {
            opacity: 0.45;
            &:before {
                background: var(--offset-text-color);
            }
            .toggle-circle {
                background: var(--offset-text-color);
            }
        }
    }

    // &:not(.is-active):hover {
    //     .toggle-bar {
    //         background: var(--text-color);
    //         .toggle-circle {
    //             background: var(--text-color);
    //         }
    //     }
    // }

    &.is-active {
        .toggle-bar {
            opacity: 1;
            &:before {
                background: var(--main-color);
            }
            .toggle-circle {
                background: var(--main-color);
                transform: translate(90%);
                right: 100%;
            }
        }
    }
}
