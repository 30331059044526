.progressbar {
    background-color: rgba(0,0,0,0.07);
    border-radius: 1em;
    margin: 0.7em 0;
    
    .progress-done {
        transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        height: 0.5em;
        display: block;
        border-radius: 1em;
        max-width: 100%;
    }
}