.notifications {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    margin: 1em;
    font-size: 1.2em;
    pointer-events: none;
    .notification {
        padding: 0.8em 1.3em;
        min-width: 7em;
        text-align: center;
        color: var(--offset-text-color);
        background: var(--main-color);
        border-radius: 0.3em;
        margin-top: 0.3em;
        // border: 0.12em solid;
        border-color: inherit;
        font-weight: bold;
        box-shadow: 0 0.225em 0.375em 0 rgba(0, 0, 0, 0.1), 0 0 0em white inset, 0 0 0em white inset;

        &.saving {
            transition: all 0ms ease;
            // border-color: transparent;
            @keyframes popIn {
                0% {
                    transform: scale(0.8);
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                    transform: scale(1);
                }
            }
            i.spinner {
                display: inline-block;
                border: transparent 0.2em solid;
                border-right-color: var(--offset-text-color);
                animation: spinner 0.3s infinite linear;
                border-radius: 99em;
                width: 0.7em;
                height: 0.7em;
                margin-inline-end: 0.3em;
                position: relative;
                top: 0.2em;
                margin-top: -1em;
                @keyframes spinner {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
            animation: popIn 350ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s forwards;
        }
        &.saved {
            transition: all 400ms ease;
            box-shadow: 0 0em 0em 0 rgba(0, 0, 0, 0.1), 0 0 0.4em white inset, 0 0 0 0.1em white inset;
            // border-color: var(--offset-text-color);
            // color: var(--main-color);
            // border-color: transparent;
            // background: var(--offset-text-color);

            @keyframes popOut {
                0% {
                    opacity: 1;
                    transform: scale(1);
                }
                100% {
                    transform: scale(0.8);
                    opacity: 0;
                }
            }
            animation: popOut 350ms cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s forwards;
        }
        &.error {
            background: var(--error-color);

            animation: error 1000ms linear both;

            /* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A0%2Cy%3A0.6%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A1%2Cy%3A1%7D%2Ct%3A%7Bx%3A1%2Cy%3A2%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */

            @keyframes error {
                0% {
                    transform: matrix3d(0.5, 0, 0, 0, 0, 0.25, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                2.8% {
                    transform: matrix3d(0.716, 0, 0, 0, 0, 0.488, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                3% {
                    transform: matrix3d(0.734, 0, 0, 0, 0, 0.512, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                5.54% {
                    transform: matrix3d(0.944, 0, 0, 0, 0, 0.863, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                6.01% {
                    transform: matrix3d(0.977, 0, 0, 0, 0, 0.929, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                8.34% {
                    transform: matrix3d(1.095, 0, 0, 0, 0, 1.206, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                9.01% {
                    transform: matrix3d(1.114, 0, 0, 0, 0, 1.262, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                11.07% {
                    transform: matrix3d(1.138, 0, 0, 0, 0, 1.349, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                12.01% {
                    transform: matrix3d(1.134, 0, 0, 0, 0, 1.35, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                14.29% {
                    transform: matrix3d(1.098, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                15.42% {
                    transform: matrix3d(1.074, 0, 0, 0, 0, 1.221, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                17.45% {
                    transform: matrix3d(1.03, 0, 0, 0, 0, 1.107, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                18.72% {
                    transform: matrix3d(1.006, 0, 0, 0, 0, 1.042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                20.67% {
                    transform: matrix3d(0.98, 0, 0, 0, 0, 0.964, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                22.02% {
                    transform: matrix3d(0.97, 0, 0, 0, 0, 0.928, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                23.82% {
                    transform: matrix3d(0.965, 0, 0, 0, 0, 0.903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                25.33% {
                    transform: matrix3d(0.968, 0, 0, 0, 0, 0.901, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                30.2% {
                    transform: matrix3d(0.993, 0, 0, 0, 0, 0.959, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                32.03% {
                    transform: matrix3d(1.001, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                36.51% {
                    transform: matrix3d(1.009, 0, 0, 0, 0, 1.03, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                38.74% {
                    transform: matrix3d(1.007, 0, 0, 0, 0, 1.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                49.26% {
                    transform: matrix3d(0.998, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                52.05% {
                    transform: matrix3d(0.998, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                62.01% {
                    transform: matrix3d(1.001, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                65.37% {
                    transform: matrix3d(1, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                70% {
                    transform: matrix3d(1, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                78.78% {
                    transform: matrix3d(1, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                92.09% {
                    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
                100% {
                    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
                }
            }
        }
    }
}
