.dashed-wrap {
    // color: rgba(0,0,0,0.2);
    color: var(--main-color);
    // background-color: var(--light-hover-color);
    // background-color: rgba(0,0,0,0.05);
    // border: 0.15em dashed rgba(0, 0, 0, 0.2);
    border: 0.12em dashed var(--main-color);
    border-radius: 0.6em;
    min-height: 8em;
    margin-bottom: 2em;
    transition: all 150ms ease;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    .inside {
        padding: 2.5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    &.is-drag-active,
    &:hover {
        background-color: var(--light-hover-color);
        // color: var(--main-color);
        // border-color: var(--main-color);
    }
    &.is-drag-regect {
        background-color: var(--error-color-hover) !important;
        color: var(--error-color) !important;
        border-color: var(--error-color) !important;
    }
    .font-image {
        cursor: pointer;
        font-size: 3em;
        margin-bottom: 0.3em;
        border-width: 0.1em;
    }
    .is-file {
        text-align: center;
        p {
            color: var(--text-color);
            font-size: 1.5em;
            font-feature-settings: 'liga' 0, 'calt' 0;
            direction: ltr;
        }
    }
    .image-wrap {
        cursor: pointer;
        .instruction {
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 0.3em;
        }
        .advise {
            font-size: 0.8em;
            font-weight: 600;
            margin-bottom: 0.3em;
            color: var(--text-color);
        }
    }
    .load-image-wrap {
        .lds-ring {
            display: inline-block;
            position: relative;
            width: 5em;
            height: 5em;
        }
        .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 4em;
            height: 4em;
            margin: 0.5em;
            border: 0.3em solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--main-color) transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes lds-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        // ---------------------> code for the loader ends here
    }
    .change-image-wrap {
        cursor: pointer;
        .container {
            img {
                max-width: 22em;
            }
        }
        .change-image {
            width: auto;
            margin-top: -2em;
        }
    }
}
