
h1,
h2,
h3,
h4,
h5 {
    margin: 2.75rem 0 1.05rem;
    // font-family: $font__main;
    font-weight: 400;
    line-height: 1.15;
}

h1 {
    margin: 0.85em 0 0.75em 0;
    font-size: 3.7em;
    margin-inline-start: -0.05em;
    &.page-title {
        @include media("<=tablet") {
            font-size: 2.5em;
            text-align: center;
        }
    }
}

h2 {
    font-size: 2.441em;
}

h3 {
    font-size: 1.953em;
}

h4 {
    font-size: 1.563em;
    margin: 0;
}

h5 {
    font-size: 1.25em;
}