nav.header-navigation {
    ul {
        display: block;
        li {
            display: inline-block;
            a {
                color: inherit;
                text-decoration: none;
                display: inline-block;
                padding: 2em 1em;
                white-space: nowrap;
            }
        }
    }
}