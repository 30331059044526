.development-notice {
    position: fixed;
    bottom: 0;
    text-align: center;
    height: auto !important;
    min-height: auto !important;
    background: rgb(0, 0, 0);
    opacity: 0.35;
    // box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.2);
    color: white;
    width: auto;
    display: block;
    margin: 0 auto;
    right:0;
    // border-radius: 0.3em 0.2em 0 0;
    // left: 50%;
    // transform: translateX(-50%);
    border-radius: 0.3em 0em 0 0;
    padding: 0.5em 0.8em 0.3em;
    line-height: 1;
    // font-weight: bold;
    font-size: 0.85em;
    pointer-events: none;
}
