.good-notice {
    border-radius: 3em;
    margin-inline-start: 0.3em;
    background-color: var(--light-hover-color);
    // background-color: var(--text-offset-color);
    color: var(--main-color);
    display: inline-block;
    padding: 0.4em 0.8em;
    font-weight: 400;
    font-size: 0.7em;
    transition: all 300ms ease;
    vertical-align: middle;
}
