.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 0.6em;
}
.mb-3 {
  margin-bottom: 1.8em;
}
.mx-4 {
  margin-inline: 0.5em;
}

.noliga {
  font-feature-settings: "liga" 0;
}
img {
  max-width: 100%;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

strong {
  font-weight: 700;
}

.popIn {
  display: block;
  @keyframes popIn {
    0% {
      transform: scale(0.7);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: popIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  // &.slow {
  //     animation-duration: 0.9s;
  // }
}
